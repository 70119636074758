import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCartaList() {
  // Use toast
  const toast = useToast()

  const refCartaListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nome_arquivo', label:"Link Arquivo"},
    { key: 'quantidade' },
    { key: 'data_hora' },
    { key: 'usuario' },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = ref({})


  const refetchData = () => {
    refCartaListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCartas = (ctx, callback) => {
    store
      .dispatch('app-carta/fetchCartas', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching carta list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCartas,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCartaListTable,

    refetchData,

  }
}
